.site-header {
    // fix odd shadow interaction on top
    background: $background-color;
}

.page__hero--overlay {
    background-position: center;
    background-origin: border-box;
    background-size: 100% auto;
    box-shadow: 0px 0px 5px 1px $text-color;
    line-height: $base-line-height * 1.5;
    z-index: $menu-z-index - 1;

    @for $h from 1 through 6 {
        h#{$h} {
            @include relative-font-size(3 - (0.5 * $h));
            font-family: $contrast-font-family;
            font-variant: normal;
        }
    }
}

.page__lead {
    @include relative-font-size(1.375);
}

.page__hero--overlay .page__title,.page__hero--overlay .page__meta,.page__hero--overlay .comment__date,.page__hero--overlay .page__lead,.page__hero--overlay .btn {
    color: $background-color;
    text-shadow: 1px 1px 4px $text-color;
}
