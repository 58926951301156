@charset "utf-8";

// Adobe fonts
@import url("https://use.typekit.net/fge4msg.css");

// Define defaults for each variable.

$sans-serif-font-family: "neue-kabel", "Gill Sans", "Gill Sans MT", Calibri, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$serif-font-family: "freight-display-pro", Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
$blackletter-font-family: "carol-gothic", $serif-font-family;
$caption-font-family: $serif-font-family;
// "la-parisienne-serif" is fun, but not the right feel for things...

$base-font-family: $sans-serif-font-family;
$contrast-font-family: $serif-font-family;
$base-font-size:   18px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$menu-z-index: 5;
$menu-border-radius: 5px;

$spacing-unit:     30px !default;

$text-color:       #111 !default;
$background-color: #fdfdfd !default;
$brand-color:      #2a7ae2 !default;
$brand-contrast-color: #e2922a !default;

$grey-color:       #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

$table-text-align: left !default;

// Width of the content area
$content-width:    1200px !default;

$on-palm:          600px !default;
$on-laptop:        800px !default;

// most of this is for notices
$primary-color: #6f777d !default;
$success-color: #3fa63f !default;
$warning-color: #d67f05 !default;
$danger-color: #ee5f5b !default;
$info-color: #3b9cba !default;
$focus-color: $primary-color !default;
$active-color: mix(#fff, $primary-color, 80%) !default;
$border-radius: 4px !default;
$notice-background-mix: 80% !default;
$code-notice-background-mix: 90% !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }

// media-query --> a max width query
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

// media-min --> a min width query
@mixin media-min($device) {
  @media screen and (min-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Import partials.
@import
  "minima/base",
  "minima/layout",
  "minima/syntax-highlighting",
  "menu",
  "notices",
  "utilities",
  "splash",
  "images",
  "text_styling"
;
