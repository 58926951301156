h1, h2, h3, h4, h5, h6, .site-title, .contrast-font {
  font-family: $contrast-font-family;
}

h1, h2, h3, h4, h5, h6 {
  font-variant: small-caps;
  font-weight: 600;
}

.site-title {
  font-weight: 700;
}

.post-link {
  font-variant: normal;
  font-family: $base-font-family;
}

@include media-min($on-laptop) {
  .two-columns {
      column-count: 2;
      column-gap: 2em;
      column-rule: 1px solid mix($grey-color-light, $grey-color-dark, 75%);
  }

  .two-columns .full-page-width {
      column-span: all;
      margin-top: 1em;
      margin-bottom: 1em;
  } 

  .no-col-break {
      -webkit-column-break-inside: avoid-column;
      -moz-column-break-inside: avoid-column;
      break-inside: avoid-column;
  }

  blockquote {
      -webkit-column-break-inside: avoid-column;
      -moz-column-break-inside: avoid-column;
      break-inside: avoid-column;
  }
}

p.has-drop-cap::first-letter {
    font-size: $base-font-size * 3;
    font-family: $blackletter-font-family;
    font-weight: 400;
    color: $brand-contrast-color;
    margin: -1.5*$base-font-size 0.25*$base-font-size -0.5*$base-font-size 0px;
    vertical-align: middle;
    line-height: .65em;
}
