// mostly taken from:
// https://github.com/mmistakes/minimal-mistakes/blob/master/_sass/minimal-mistakes/_utilities.scss

/*
   Type
   ========================================================================== */

$text-alignments: left, right, start, end, center, justify;
@each $alignment in $text-alignments {
  .text-#{$alignment} {
    text-align: $alignment;
  }
}

.text-nowrap {
  white-space: nowrap;
}

/*
   Task lists
   ========================================================================== */

.task-list {
  padding: 0;

  li {
    list-style-type: none;
  }

  .task-list-item-checkbox {
    margin-inline-end: 0.5em;
    opacity: 1;
  }
}

.task-list .task-list {
  margin-inline-start: 1em;
}

/*
   Images
   ========================================================================== */

/* image align left */

.align-left {
  display: block;
  margin-inline: auto;
  float: left;
  margin-right: 1em;

  /*@include breakpoint($small) {
    float: left;
    margin-right: 1em;
  }*/
}

/* image align right */

.align-right {
  display: block;
  margin-inline: auto;
  float: right;
  margin-left: 1em;

  /*@include breakpoint($small) {
    float: right;
    margin-left: 1em;
  }*/
}

/* image align center */

.align-center {
  display: block;
  margin-inline: auto;
}

/*
   Sticky, fixed to top content
   ========================================================================== */
/*
.sticky {
  @include breakpoint($large) {
    @include clearfix();
    position: -webkit-sticky;
    position: sticky;
    top: 2em;

    > * {
      display: block;
    }
  }
}*/


/*
   Footnotes
   ========================================================================== */

.footnote {
  color: mix(#fff, $grey-color, 25%);
  text-decoration: none;
}

.footnotes {
  color: mix(#fff, $grey-color, 25%);

  ol,
  li,
  p {
    margin-bottom: 0;
    font-size: .9em;
  }
}

a.reversefootnote {
  color: $grey-color;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

/*
   Required
   ========================================================================== */

.required {
  color: $danger-color;
  font-weight: bold;
}
