figure:not(.full-page-width) {
    margin: 1em;
    box-shadow: 0 0 5px -1px $grey-color-dark;
    font-family: $caption-font-family;
    font-size: $small-font-size;
    width: fit-content;
    max-width: 50%;
    position: relative;
    display: table;

    img {
        max-width: 100%;
    }

    figcaption {
        padding: 3px 5px;
        display: table-caption;
        caption-side: bottom;
    }
}

// tweak margins for the floaters
@each $dir in left, right {
    figure.align-#{$dir}:not(.full-page-width) {
        margin-#{$dir}: 0;
    }
}

// disable floaters on mobile
@include media-query($on-palm) {
    @each $dir in left, right {
        figure::before, figure::after {
            clear: both;
        }
        
        figure.align-#{$dir}:not(.full-page-width) {
            //display: block;
            max-width: 100%;
            left: auto;
            right: auto;
            float: none;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

figure.full-page-width {
    width: 100%;
    position: relative;

    img {
        width: 100%;
        height: auto;
        -ms-interpolation-mode: bicubic;
        box-shadow: 0 0 5px 1px mix($grey-color-dark,$grey-color-light,50%);
    }

    figcaption {
        position: absolute;
        bottom: 0;
        inset-inline-end: 0;
        margin: 0 auto;
        padding: 2px 5px;
        background: $text-color;
        color: $background-color;
        font-family: $caption-font-family;
        font-size: $small-font-size;
        text-align: end;
        z-index: $menu-z-index - 1;
        opacity: 0.75;

        a {
            color: mix($brand-color, $background-color, 70%);
            text-decoration: none;
        }
        a:hover {
            color: mix($brand-color, $background-color, 30%);
            text-decoration: underline;
        }
    }
}
