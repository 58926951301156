.site-header {
  box-shadow: 0 0 10px 1px $grey-color-light;
}

.site-nav2 {
  float: right;
  line-height: $base-line-height;

    ul {
      background: $background-color;
      list-style: none;
      margin: 0;
      padding: 0;
    }

    ul li {
      color: $text-color;
      background: $background-color;
      //display: inline-block;
      float: left;
      position: relative;
      text-decoration: none;
      transition: all 0.5s ease;
    }

    ul li a {
      display: block;
      padding: 0 10px;
      color: $text-color;
      line-height: $base-line-height * $base-font-size * 2.25;
    }

    ul li a:hover,
    ul li a:focus-within {
      text-shadow: 0px 0px 7px mix($brand-color, $background-color, 60%);
    }

    //box-shadow: x y blur spread color inset
    li:hover,
    li:focus-within {
      cursor: pointer;
      background: mix($background-color, $grey-color-light, 35%);
    }

    li:focus-within a {
      outline: none;
    }

    ul li ul {
      background: $background-color;
      border: 1px solid $grey-color-light;
      border-radius: 0 0 $menu-border-radius $menu-border-radius;
      visibility: hidden;
      opacity: 0;
      position: absolute;
      transition: all 0.5s ease;
      left: auto;
      right: 1px;
      display: none;
      box-shadow: 0 2px 4px $grey-color-light;
      z-index: $menu-z-index;
    }

    ul li:hover > ul,
    ul li:focus-within > ul,
    ul li ul:hover,
    ul li ul:focus {
       visibility: visible;
       opacity: 1;
       display: block;
    }

    ul li ul li {
      clear: both;
      width: 100%;
      display: block;
      white-space: nowrap;
    }

    ul li ul li:last-child {
      border-radius: 0 0 $menu-border-radius $menu-border-radius;
    }

  .nav-trigger {
      display: none;
  }

  .menu-icon {
    display: none;
  }

  .page-link {
    color: $text-color;
  }

  @include media-query($on-palm) {
    position: absolute;
    top: 9px;
    right: $spacing-unit * 0.5;
    background-color: $background-color;
    border: 1px solid $grey-color-light;
    border-radius: $menu-border-radius;
    text-align: right;
    z-index: $menu-z-index;

    ul li {
      float: none;
      display: block;
    }

    // disable submenus on mobile until I figure out another way
    ul li:hover > ul,
    ul li:focus-within > ul,
    ul li ul:hover,
    ul li ul:focus {
       visibility:hidden;
       opacity: 0;
       display: none;
    }

    label[for="nav-trigger"] {
      display: block;
      float: right;
      width: 36px;
      height: 36px;
      z-index: 2;
      cursor: pointer;
    }

    .menu-icon {
      display: block;
      float: right;
      width: 36px;
      height: 26px;
      line-height: 0;
      padding-top: 10px;
      text-align: center;

      > svg {
        fill: $grey-color-dark;
      }
    }

    input ~ .trigger {
      clear: both;
      display: none;
    }

    input:checked ~ .trigger {
      display: block;
      padding-bottom: 5px;
    }

    .page-link {
      display: block;
      margin-left: 20px;
      padding: 5px 10px;

      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }
}
